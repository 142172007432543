import { Fragment, useState, useEffect } from 'react'
import { Disclosure, Menu, Switch, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import
    {
        Bars3Icon,
        BellIcon,
        CogIcon,
        CreditCardIcon,
        KeyIcon,
        SquaresPlusIcon,
        UserCircleIcon,
        XMarkIcon,
    } from '@heroicons/react/24/outline'
import { NavLink } from 'react-router-dom'
const user = {
    name: 'Debbie Lewis',
    handle: 'deblewis',
    email: 'debbielewis@example.com',
    imageUrl:
        'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=320&h=320&q=80',
}
const navigation = [
    { name: 'Dashboard', href: '#', current: true },
    { name: 'Jobs', href: '#', current: false },
    { name: 'Applicants', href: '#', current: false },
    { name: 'Company', href: '#', current: false },
]
const subNavigation = [    
    { name: 'Account', href: '/settings/account', icon: CogIcon, current: false },
    { name: 'Preferences', href: '/settings/preferences', icon: KeyIcon, current: false },    
    // { name: 'Billing', href: 'settings/billing', icon: CreditCardIcon, current: false },    
]

const updateTheme = (value) => {
    if (value != 'system')
        localStorage.theme = value;
    else
        localStorage.removeItem('theme');

    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches))
        document.documentElement.classList.add('dark')
    else
        document.documentElement.classList.remove('dark')
}

function classNames(...classes)
{
    return classes.filter(Boolean).join(' ')
}

export default function Preferences()
{
    const [appearanceMode, setAppearanceMode] = useState(localStorage.theme || 'light');

    const [availableToHire, setAvailableToHire] = useState(true)
    const [privateAccount, setPrivateAccount] = useState(false)
    const [allowCommenting, setAllowCommenting] = useState(true)
    const [allowMentions, setAllowMentions] = useState(true)

    useEffect(() => {
        updateTheme(appearanceMode);
    }, [appearanceMode])  
    
    useEffect(() => {

    }, []);

    return (
        <div>
            <Disclosure as="div" className="relative overflow-hidden bg-primary pb-32">
                {({ open }) => (
                    <>
                        {/* Fancy */}
                        <div
                            aria-hidden="true"
                            className={classNames(
                                open ? 'bottom-0' : 'inset-y-0',
                                'absolute inset-x-0 left-1/2 w-full -translate-x-1/2 transform overflow-hidden lg:inset-y-0'
                            )}
                        >
                            <div className="absolute inset-0 flex">
                                <div className="h-full w-1/2" style={{ backgroundColor: '#34126e' }} />
                                <div className="h-full w-1/2" style={{ backgroundColor: '#34126e' }} />
                            </div>
                            <div className="relative flex justify-center">
                                <svg
                                    className="flex-shrink-0"
                                    width={1750}
                                    height={308}
                                    viewBox="0 0 1750 308"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M284.161 308H1465.84L875.001 182.413 284.161 308z" fill="#0369a1" />
                                    <path d="M1465.84 308L16.816 0H1750v308h-284.16z" fill="#34126e" />
                                    <path d="M1733.19 0L284.161 308H0V0h1733.19z" fill="#34126e" />
                                    <path d="M875.001 182.413L1733.19 0H16.816l858.185 182.413z" fill="#411986" />
                                </svg>
                            </div>
                        </div>

                        <header className="relative py-10">
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <h1 className="text-3xl font-bold tracking-tight text-white">Settings</h1>
                            </div>
                        </header>
                    </>
                )}
            </Disclosure>

            <main className="relative -mt-32">
                <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
                    <div className="overflow-hidden rounded-lg bg-gray shadow">
                        <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                            <aside className="py-6 lg:col-span-3">
                                <nav className="space-y-1">
                                    {subNavigation.map((item) => (
                                        <NavLink
                                            to={item.href}
                                            key={item.name}
                                            aria-current={({ isActive }) => isActive ? 'page' : undefined}
                                            className={({ isActive }) => classNames(isActive
                                                ? 'bg-teal-50 border-accent text-primary hover:bg-primary hover:text-accent'
                                                : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                                                'group border-l-4 px-3 py-2 flex items-center text-sm font-medium')}
                                        >
                                            {({ isActive }) => (
                                                <>
                                                    <item.icon
                                                        className={classNames(
                                                            isActive
                                                                ? 'text-primary group-hover:text-accent'
                                                                : 'text-gray-400 group-hover:text-gray-500',
                                                            'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    <span className="truncate">{item.name}</span>
                                                </>
                                            )}
                                        </NavLink>
                                    ))}
                                </nav>
                            </aside>

                            <form className="divide-y divide-gray-200 lg:col-span-9" action="#" method="POST">
                                <div className="divide-y divide-gray-200 py-6">
                                    <div className="px-4 sm:px-6">
                                        <div className='mb-6'>
                                            <h2 className="text-lg font-medium leading-6 text-textprimary">Preferences</h2>                                            
                                        </div>

                                        <div className="">
                                            <label htmlFor="appearance" className="block text-sm font-medium leading-6 text-textprimary">
                                                Appearance
                                            </label>
                                            <select
                                                id="appearance"
                                                name="appearance"
                                                autoComplete="appearance-name"                          
                                                value={appearanceMode}                      
                                                onChange={(e) => setAppearanceMode(e.target.value)}
                                                className="mt-2 block w-full rounded-md border-0 bg-background px-3 py-1.5 text-textprimary shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"
                                            >
                                                <option value={'light'}>Light</option>
                                                <option value={'dark'}>Dark</option>
                                                <option value={'system'}>System Preference</option>
                                            </select>
                                        </div>

                                        {/* <ul role="list" className="mt-2 divide-y divide-gray-200">
                                            <Switch.Group as="li" className="flex items-center justify-between py-4">
                                                <div className="flex flex-col">
                                                    <Switch.Label as="p" className="text-sm font-medium text-gray-900" passive>
                                                        Available to hire
                                                    </Switch.Label>
                                                    <Switch.Description className="text-sm text-gray-500">
                                                        Nulla amet tempus sit accumsan. Aliquet turpis sed sit lacinia.
                                                    </Switch.Description>
                                                </div>
                                                <Switch
                                                    checked={availableToHire}
                                                    onChange={setAvailableToHire}
                                                    className={classNames(
                                                        availableToHire ? 'bg-teal-500' : 'bg-gray-200',
                                                        'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                                                    )}
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        className={classNames(
                                                            availableToHire ? 'translate-x-5' : 'translate-x-0',
                                                            'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                        )}
                                                    />
                                                </Switch>
                                            </Switch.Group>
                                            <Switch.Group as="li" className="flex items-center justify-between py-4">
                                                <div className="flex flex-col">
                                                    <Switch.Label as="p" className="text-sm font-medium text-gray-900" passive>
                                                        Make account private
                                                    </Switch.Label>
                                                    <Switch.Description className="text-sm text-gray-500">
                                                        Pharetra morbi dui mi mattis tellus sollicitudin cursus pharetra.
                                                    </Switch.Description>
                                                </div>
                                                <Switch
                                                    checked={privateAccount}
                                                    onChange={setPrivateAccount}
                                                    className={classNames(
                                                        privateAccount ? 'bg-teal-500' : 'bg-gray-200',
                                                        'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                                                    )}
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        className={classNames(
                                                            privateAccount ? 'translate-x-5' : 'translate-x-0',
                                                            'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                        )}
                                                    />
                                                </Switch>
                                            </Switch.Group>
                                            <Switch.Group as="li" className="flex items-center justify-between py-4">
                                                <div className="flex flex-col">
                                                    <Switch.Label as="p" className="text-sm font-medium text-gray-900" passive>
                                                        Allow commenting
                                                    </Switch.Label>
                                                    <Switch.Description className="text-sm text-gray-500">
                                                        Integer amet, nunc hendrerit adipiscing nam. Elementum ame
                                                    </Switch.Description>
                                                </div>
                                                <Switch
                                                    checked={allowCommenting}
                                                    onChange={setAllowCommenting}
                                                    className={classNames(
                                                        allowCommenting ? 'bg-teal-500' : 'bg-gray-200',
                                                        'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                                                    )}
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        className={classNames(
                                                            allowCommenting ? 'translate-x-5' : 'translate-x-0',
                                                            'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                        )}
                                                    />
                                                </Switch>
                                            </Switch.Group>
                                            <Switch.Group as="li" className="flex items-center justify-between py-4">
                                                <div className="flex flex-col">
                                                    <Switch.Label as="p" className="text-sm font-medium text-gray-900" passive>
                                                        Allow mentions
                                                    </Switch.Label>
                                                    <Switch.Description className="text-sm text-gray-500">
                                                        Adipiscing est venenatis enim molestie commodo eu gravid
                                                    </Switch.Description>
                                                </div>
                                                <Switch
                                                    checked={allowMentions}
                                                    onChange={setAllowMentions}
                                                    className={classNames(
                                                        allowMentions ? 'bg-teal-500' : 'bg-gray-200',
                                                        'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                                                    )}
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        className={classNames(
                                                            allowMentions ? 'translate-x-5' : 'translate-x-0',
                                                            'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                        )}
                                                    />
                                                </Switch>
                                            </Switch.Group>
                                        </ul> */}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
