import React, {useState, Fragment} from 'react';
import chroma from "chroma-js";
import { useNavigate } from "react-router-dom";
import { useCampaignContext } from "../../contexts/CampaignContext";
import IconCollection from '../../utilities/IconMap';
import { ArrowUturnRightIcon } from "@heroicons/react/24/outline";

export default function Note(props) {    

  const campaignContext = useCampaignContext();
  const { currentCampaignId, getStyle } = campaignContext;

  const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState(false);

  const noteJSON = JSON.parse(props.note.NoteText);
  
  let NoteTypeIcon = null;

  if (props.noteTypes.length > 0) {
    const noteType = props.noteTypes.find((x) => x.Id == props.note.NoteTypeId);
    NoteTypeIcon = noteType?.Icon ? IconCollection.find(x => x.Name == noteType?.Icon).Icon : null;
  }

  const goToSession = (SessionId, NoteId) => {        
    navigate(`/campaigns/${currentCampaignId}/session/${SessionId}/${NoteId}`);
  }

  return (    
      <div className='flex flex-row p-1 grow gap-2 items-center cursor-pointer rounded ThemeBackground' onMouseOver={() => setIsHovering(true)} onMouseOut={() => setIsHovering(false)} onClick={() => goToSession(props.note.SessionId, props.note.Id)}>
        {isHovering ? <ArrowUturnRightIcon className='w-5 min-w-[20px] h-5' /> : NoteTypeIcon ? <NoteTypeIcon className="w-5 min-w-[20px] h-5" /> : <div className="w-[20px] min-w-[20px] h-[20px]"></div>}
        <div className="inline-block grow leading-7">
          {noteJSON.map((element, index) => {
              switch (element.type) {
                case "note":
                  return <span key={index}>{element.value}</span>;
                case "mention":
                  let Key = props.keys.find((x) => x.Id == element.TagId);                  
                  let TextColor = Key?.Color ? chroma.contrast(Key?.Color, "white") > 3.5 ? "white" : "black" : "var(--text-primary)";
                  return (
                    <span
                      key={index}
                      className={"tag p-[0.2rem] rounded " + getStyle(Key)}
                      style={{ backgroundColor: Key?.Color, color: TextColor }}
                      tagid={element.TagId}
                      tagtype={element.type}
                    >
                      {element.value}
                    </span>
                  );
              }
            })
          }
        </div>  
      </div>
  );
}