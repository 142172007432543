import { ICON_GROUP } from "./Constants";
import
    {
        InformationCircleIcon,        
        ExclamationCircleIcon, 
        CheckIcon, 
        ChatBubbleBottomCenterTextIcon, 
        FlagIcon, 
        PencilIcon, 
        BanknotesIcon, 
        BoltIcon, 
        FireIcon, 
        BeakerIcon, 
        MusicalNoteIcon, 
        ClockIcon, 
        XMarkIcon,
        MoonIcon,
    } from "@heroicons/react/24/outline";

import
    {
        BriefcaseIcon,
        CalendarIcon,
        ChevronDownIcon,
        MapPinIcon,
        PencilIcon as PencilIconSolid,
        PlusIcon,
        KeyIcon,
        MapIcon,
        UserPlusIcon,
        TrashIcon,        
        Cog6ToothIcon
    } from "@heroicons/react/20/solid";

import {
    Anvil,
    Axe,
    Bone,
    Brain,
    Castle,
    CloudRainIcon,
    Crown,
    Droplet,
    Feather,
    Flame,
    FlameKindling,
    FlaskConical,
    FlaskRound,
    Ghost,
    Heart,
    Hourglass,
    Milestone,
    Sailboat,
    Scroll,
    ScrollText,
    Shovel,
    Skull,
    Sparkles,
    Swords,
    Sword,
    Tent,
    VenetianMask,
    Wind,
} from 'lucide-react';

const IconCollection = [
    { Name: "Anvil", Icon: Anvil, Group: ICON_GROUP.FantasyMagic },
    { Name: "Axe", Icon: Axe, Group: ICON_GROUP.FantasyMagic },
    { Name: "Beaker", Icon: BeakerIcon, Group: ICON_GROUP.NatureElements },
    { Name: "Bolt", Icon: BoltIcon, Group: ICON_GROUP.NatureElements },
    { Name: "Bone", Icon: Bone, Group: ICON_GROUP.FantasyMagic },
    { Name: "Brain", Icon: Brain, Group: ICON_GROUP.FantasyMagic },
    { Name: "Briefcase", Icon: BriefcaseIcon, HideFromIconPicker: true },
    { Name: "Calendar", Icon: CalendarIcon, Group: ICON_GROUP.ObjectSymbols },
    { Name: "Castle", Icon: Castle, Group: ICON_GROUP.FantasyMagic },
    { Name: "ChevronDown", Icon: ChevronDownIcon, HideFromIconPicker: true },
    { Name: "Check", Icon: CheckIcon, Group: ICON_GROUP.ObjectSymbols  },
    { Name: "Clock", Icon: ClockIcon, Group: ICON_GROUP.Default },
    { Name: "Cloud", Icon: CloudRainIcon, Group: ICON_GROUP.NatureElements },
    { Name: "Cog", Icon: Cog6ToothIcon, Group: ICON_GROUP.ObjectSymbols  },
    { Name: "Crown", Icon: Crown, Group: ICON_GROUP.FantasyMagic  },
    { Name: "Droplet", Icon: Droplet, Group: ICON_GROUP.NatureElements },
    { Name: "ExclamationCircle", Icon: ExclamationCircleIcon, Group: ICON_GROUP.Default },
    { Name: "Feather", Icon: Feather, Group: ICON_GROUP.ObjectSymbols },
    { Name: "Fire", Icon: FireIcon, Group: ICON_GROUP.Default  },
    { Name: "Flag", Icon: FlagIcon, Group: ICON_GROUP.Default },
    { Name: "Flame", Icon: Flame, Group: ICON_GROUP.NatureElements },
    { Name: "FlameKindling", Icon: FlameKindling, Group: ICON_GROUP.NatureElements },
    { Name: "FlaskConical", Icon: FlaskConical, Group: ICON_GROUP.NatureElements },
    { Name: "FlaskRound", Icon: FlaskRound, Group: ICON_GROUP.NatureElements },
    { Name: "Ghost", Icon: Ghost, Group: ICON_GROUP.FantasyMagic },
    { Name: "Heart", Icon: Heart, Group: ICON_GROUP.Default },
    { Name: "Hourglass", Icon: Hourglass, Group: ICON_GROUP.Default },
    { Name: "InformationCircle", Icon: InformationCircleIcon, Group: ICON_GROUP.Default },
    { Name: "Key", Icon: KeyIcon, Group: ICON_GROUP.ObjectSymbols },    
    { Name: "Map", Icon: MapIcon, HideFromIconPicker: true },
    { Name: "MapPin", Icon: MapPinIcon, Group: ICON_GROUP.ObjectSymbols },
    { Name: "Milestone", Icon: Milestone, Group: ICON_GROUP.ObjectSymbols },
    { Name: "Money", Icon: BanknotesIcon, Group: ICON_GROUP.ObjectSymbols },
    { Name: "Moon", Icon: MoonIcon, Group: ICON_GROUP.FantasyMagic },
    { Name: "MusicNote", Icon: MusicalNoteIcon, Group: ICON_GROUP.FantasyMagic },
    { Name: "Pencil", Icon: PencilIcon, HideFromIconPicker: true },
    { Name: "PencilSolid", Icon: PencilIconSolid, HideFromIconPicker: true },
    { Name: "Plus", Icon: PlusIcon, HideFromIconPicker: true },
    { Name: "Quest", Icon: ExclamationCircleIcon, Group: ICON_GROUP.Default },
    { Name: "Quote", Icon: ChatBubbleBottomCenterTextIcon, Group: ICON_GROUP.Default },
    { Name: "Sailboat", Icon: Sailboat, Group: ICON_GROUP.ObjectSymbols },
    { Name: "Scroll", Icon: Scroll, Group: ICON_GROUP.Default },
    { Name: "ScrollText", Icon: ScrollText, Group: ICON_GROUP.ObjectSymbols },
    { Name: "Shovel", Icon: Shovel, Group: ICON_GROUP.ObjectSymbols },
    { Name: "Skull", Icon: Skull, Group: ICON_GROUP.Default },
    { Name: "Sparkles", Icon: Sparkles, Group: ICON_GROUP.FantasyMagic },
    { Name: "Sword", Icon: Sword, Group: ICON_GROUP.Default },
    { Name: "Swords", Icon: Swords, Group: ICON_GROUP.FantasyMagic },
    { Name: "Tent", Icon: Tent, Group: ICON_GROUP.Default },
    { Name: "Trash", Icon: TrashIcon, HideFromIconPicker: true },
    { Name: "UserPlus", Icon: UserPlusIcon, HideFromIconPicker: true },
    { Name: "VenetianMask", Icon: VenetianMask, Group: ICON_GROUP.FantasyMagic },
    { Name: "Wind", Icon: Wind, Group: ICON_GROUP.NatureElements },
    { Name: "XMark", Icon: XMarkIcon, HideFromIconPicker: true },
];

export default IconCollection;