import React from "react";

export default function Input({
  label,
  value,
  type,
  name,
  onChange,
  onKeyDown,
  key,
  index,
  error,
  className,
  maxLength,
}) {
  return (
    <div key={key} className="flex-1 col-span-2 sm:col-span-2">
      <label
        htmlFor="price"
        className="block text-sm font-medium text-textprimary"
      >
        {label}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          type={type}
          data-lpignore="true"
          className={`bg-background border ${
            error ? "border-red-500" : "border-border"
          } text-textprimary text-sm rounded-md focus:ring-primary focus:border-primary outline-none block w-full min-w-[200px] py-2 px-2 ${className || ""}`}
          value={value}
          name={name}
          onChange={(event) => onChange(event, index)}
          onKeyDown={onKeyDown ? (event) => onKeyDown(event) : null}
          maxLength={maxLength ? maxLength : 200}
        />
        {error && <span className="text-red-500 text-xs">{error}</span>}
      </div>
    </div>
  );
}
