import { EnvelopeIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { ListChecks, Keyboard} from "lucide-react";

const features = [
  {
    name: "Keep track of Quests",
    description:
      "Use your naturally occuring notes to turn them into a check list you can refer back to later.",
    icon: ListChecks,
  },
  {
    name: "Advanced Search",
    description:
      "For when you really need to narrow things down.",
    icon: MagnifyingGlassIcon,
  },
  {
    name: "Tag Manager",
    description: `See how often you use certain tags and update existing ones.`,
    secondDescription:  `(Spelling can be hard sometimes...)`,
    icon: Keyboard,
  },
  {
    name: "Invite your friends",
    description:
      "Campaigns support multiple note-takers, the more the merrier.",
    icon: EnvelopeIcon,
  },
];

export default function SecondaryFeatures() {
  return (
    <div className="bg-background py-12">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">          
          <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            A better way to manage your notes
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Lots of additional features to make note-taking worth while and easier for you.
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 md:space-y-0">
            {features.map((feature) => (
              <div
                key={feature.name}
                className="relative"
              >
                <dt>
                  <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-accent text-white">
                    <feature.icon
                      className="h-6 w-6"
                      aria-hidden="true"
                    />
                  </div>
                  <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  {feature.description}
                  <br></br>
                  {feature.secondDescription}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
