import React from "react";
import { useNavigate } from "react-router-dom";

import { PlusIcon } from '@heroicons/react/20/solid';
import { Scroll } from 'lucide-react';
import Button from "../Atoms/Button";

function EmptyStateCampaignList() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center h-full justify-center">
      <Scroll className="w-10 h-10" color="var(--primary)" />
      <h3 className="mt-2 text-sm font-semibold text-textprimary">No Campaigns</h3>
      <p className="mt-1 text-sm text-textprimary">Begin a new Adventure by creating a new Campaign.</p>
      <div className="mt-6">
          <Button
            label={"New Campaign"}
            bgColor='bg-primary'
            textColor="text-texttertiary" 
            border="border-border"
            icon={PlusIcon}
            onClick={() => { navigate("/campaigns/create") }}
          />          
      </div>
    </div>
  );
}

export default EmptyStateCampaignList;
