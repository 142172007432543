import React from "react";

import { PlusIcon } from '@heroicons/react/20/solid';
import { Scroll } from 'lucide-react';
import Button from "../Atoms/Button";

function EmptyStateCampaign({onClick}) {  

  return (
    <div className="flex flex-col items-center h-full justify-center">
      <Scroll className="w-10 h-10" color="var(--primary)" />
      <h3 className="mt-2 text-sm font-semibold text-textprimary">No Sessions!</h3>
      <p className="mt-1 text-sm text-textprimary">Add a new session to get started taking some notes!</p>
      <div className="mt-6">
          <Button
            label={"New Session"}
            bgColor='bg-primary'
            textColor="text-texttertiary" 
            border="border-border"
            icon={PlusIcon}
            onClick={(e) => onClick(e)}
          />          
      </div>
    </div>
  );
}

export default EmptyStateCampaign;
