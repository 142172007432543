import React from 'react';
import Footer from '../components/Infrastructure/Footer.jsx';
import LogoIcon from "../components/Atoms/LogoIcon";

export default function Discord()
{   
    let theme = localStorage.getItem("theme") || "dark";

    return (
        <>
            <div className='h-full flex justify-center items-center flex-col gap-2'>
                <div className='flex justify-center items-center gap-2'>
                    <span className='font-bold'>Join us on Discord to ask questions, suggest new features, and meet other inkless users!</span>
                </div>
                <main className="grid place-items-center bg-background px-6 py-12 sm:py-12 lg:px-8">
                    <iframe
                        src={`https://discord.com/widget?id=1148028299130454027&theme=${theme}`}
                        width="350"
                        height="500"
                        allowtransparency="true"
                        frameborder="0"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts">
                    </iframe>
                </main>
            </div>
            <Footer position={`relative`} />
        </>
    );
}
