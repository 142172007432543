import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from '@auth0/auth0-react';
import { useCampaignContext } from "../contexts/CampaignContext";

const useUserInformation = (campaignId) => {
  const { user } = useAuth0();
  const campaignData = useCampaignContext();  
  const { getPartyMembers } = campaignData;

  const partyMembersQuery = useQuery(["PartyMembers", campaignId], () => getPartyMembers(campaignId));

  // Filter party members based on user.sub
  const filteredPartyMembers = partyMembersQuery.data?.filter(member => member.UserId === user.sub) || [];

  return filteredPartyMembers ? filteredPartyMembers[0] : null;
};

export default useUserInformation;
