import { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";

import screenshotNotes from "../../images/screenshots/Notes2.png";
import screenshotLegend from "../../images/screenshots/Legend.png";
import screenshotQuickSearch from "../../images/screenshots/QuickSearch.png";
import screenshotThemes from "../../images/screenshots/Themes1.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const features = [
  {
    title: "Aesthetic Notes",
    description:
      "Create decorative and informative notes with the use Tags & Note Types that let you know what you're looking at with ease.",
    image: screenshotNotes,
  },
  {
    title: "Tags & Note Types",
    description:
      "All of your receipts organized into one place, as long as you don't mind typing in the data by hand.",
    image: screenshotLegend,
  },
  {
    title: "Searching",
    description:
      "Quickly search all of your notes from anywhere inside your campaign.",
    image: screenshotQuickSearch,
  },
  {
    title: "Campaign Themes",
    description:
      "Choose from multiple themes that fit you or your campaigns style.",
    image: screenshotThemes,
  },
];

export default function PrimaryFeatures() {
  let [tabOrientation, setTabOrientation] = useState("horizontal");

  useEffect(() => {
    let lgMediaQuery = window.matchMedia("(min-width: 1024px)");

    function onMediaQueryChange({ matches }) {
      setTabOrientation(matches ? "vertical" : "horizontal");
    }

    onMediaQueryChange(lgMediaQuery);
    lgMediaQuery.addEventListener("change", onMediaQueryChange);

    return () => {
      lgMediaQuery.removeEventListener("change", onMediaQueryChange);
    };
  }, []);

  return (
    <section
      id="features"
      aria-label="Features for running your books"
      className="relative overflow-hidden bg-primary pt-20 pb-28 sm:py-32"
    >
      <div className="max-w-2xl mx-auto px-2 md:text-center xl:max-w-none">
        <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
          Customize your notes how you want to.
        </h2>
        <p className="mt-6 text-lg tracking-tight text-blue-100">
          Your notes should work for you, and look great while doing it.
        </p>
      </div>
      <div className="mx-auto lg:ml-32 lg:mr-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
        <Tab.Group
          as="div"
          className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
          vertical={tabOrientation === "vertical"}
        >
          {({ selectedIndex }) => (
            <>
              <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                <Tab.List className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
                  {features.map((feature, featureIndex) => (
                    <div
                      key={feature.title}
                      className={classNames(
                        "group relative rounded-full py-1 px-4 lg:rounded-r-none lg:rounded-l-xl lg:p-6",
                        selectedIndex === featureIndex
                          ? "bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10"
                          : "hover:bg-white/10 lg:hover:bg-white/5"
                      )}
                    >
                      <h3>
                        <Tab
                          className={classNames(
                            "font-display text-lg [&:not(:focus-visible)]:focus:outline-none",
                            selectedIndex === featureIndex
                              ? "text-blue-600 lg:text-white"
                              : "text-blue-100 hover:text-white lg:text-white"
                          )}
                        >
                          <span className="absolute inset-0 rounded-full lg:rounded-r-none lg:rounded-l-xl" />
                          {feature.title}
                        </Tab>
                      </h3>
                      <p
                        className={classNames(
                          "mt-2 hidden text-sm lg:block",
                          selectedIndex === featureIndex
                            ? "text-white"
                            : "text-blue-100 group-hover:text-white"
                        )}
                      >
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels className="lg:col-span-7">
                {features.map((feature) => (
                  <Tab.Panel key={feature.title} unmount={false}>
                    <div className="relative sm:px-6 lg:hidden">
                      <div className="absolute -inset-x-4 top-[-6.5rem] bottom-[-4.25rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
                      <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                        {feature.description}
                      </p>
                    </div>
                    <div className="mt-10 h-[38rem] w-[45rem] overflow-hidden rounded-xl bg-white shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem] flex items-center justify-center">
                      <img className="h-min rounded-md shadow-2xl" src={feature.image} />
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </>
          )}
        </Tab.Group>
      </div>
    </section>
  );
}
