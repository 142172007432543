import { View } from "lucide-react"

export const THEME_TYPES = {    
    Default: 0,
    Gothic: 1,
    Apocalypse: 2,
    Plains: 3,
    Mountains: 4,
    Nautical: 5,
    Desert: 6,
    GothicDark: 7,
}

export const ICON_GROUP = {
    Default: "Default",
    ObjectSymbols: "Objects & Symbols",
    NatureElements: "Nature & Elements",
    FantasyMagic: "Fantasy & Magic",
}

export const PERMISSIONS = {
    Editor: 1,
    Viewer: 2,
}