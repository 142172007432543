import React from "react";

export default function Button({
  rounding = "rounded-md",
  bgColor = "bg-primary",
  border = "border-transparent",
  textColor = "text-texttertiary",
  hoverColor = "hover:bg-primaryhover",
  hoverTextColor = "group-hover:text-white",
  icon,
  label,
  onClick,
  id,
}) {
  const Icon = icon;

  // Note: The group-hover are not able to be dynamic apparently? Something to do with how tailwind compiles?

  return (    
      <button 
        id={id}
        type="button"
        className={`inline-flex justify-center items-center ${rounding} border ${border} ${bgColor} py-2 px-4 gap-2 text-sm ${textColor} 
        shadow-sm ${hoverColor} group focus:outline-none focus:ring-2 focus:ring-primary-hover focus:ring-offset-2`}
        onClick={() => onClick()}
      >
        {icon && (
          <div className={`h-5 w-5 text-gray-400`}>
            <Icon className={`${textColor} ${hoverColor} group-hover:text-white`} />
          </div>
        )}
        <span className={`font-semibold whitespace-nowrap ${hoverTextColor}`}>{label}</span>
      </button>    
  );
}
