import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';
import { useAuth0 } from "@auth0/auth0-react";

import  Button from '../Atoms/Button';
import Container  from '../Atoms/Container';
import LogoIcon from '../Atoms/LogoIcon';

const planTypes = [{type: "Monthly", text: "Monthly"}, {type: "Annually", text: "Yearly (Save 16%)"}]

const plans = [
  {
    name: 'Free Inker',
    featured: false,
    price: { Monthly: '$0', Annually: '$0' },
    description:
      'Get started taking notes for free right now.',
    button: {
      label: 'Get started for free',
      href: '/register',
    },
    features: [
      'One Campaign',
      'Four Tag & Note Types',
      'Tag Manager',
    ],
    logomarkClassName: 'fill-gray-300',
  },
  {
    name: 'Premium',
    featured: true,
    price: { Monthly: '$5 / month', Annually: '$50 / year' },
    description:
      'Help support Inkless and get access to every and all features.',
    button: {
      label: 'Subscribe',
      href: '/register',
    },
    features: [
      'Unlimited Campaigns',
      'Custom Tag and Note Types',
      'Access to All Campaign Themes',
      'Expanded Icon Library',
      'Unlimited collaborators',
      'Tag Manager',
      'Check List',
      'Advanced Searching',      
      'All upcoming new features...',
    ],
    logomarkClassName: 'fill-gray-500',
  },  
]

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path
        d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
        fill="currentColor"
      />
      <circle
        cx="12"
        cy="12"
        r="8.25"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function Plan({
  name,
  price,
  description,
  button,
  features,
  activePeriod,
  logomarkClassName,
  featured = false,
}) {
  const { loginWithRedirect } = useAuth0();

  return (
    <section
      className={clsx(
        'flex flex-col overflow-hidden rounded-3xl p-6 gap-3 shadow-lg shadow-gray-900/5',
        featured ? 'order-first bg-primary lg:order-none' : 'bg-gray',
      )}
    >
      <h3
        className={clsx(
          'flex items-center text-sm font-semibold',
          featured ? 'text-white' : 'text-gray-900',
        )}
      >
        <LogoIcon fill={featured ? "fill-white" : "fill-primary"} className={clsx('h-7 w-7 flex-none')} />
        <span className="ml-4">{name}</span>
      </h3>
      <p
        className={clsx(
          'relative flex text-3xl tracking-tight',
          featured ? 'text-white' : 'text-gray-900',
        )}
      >
        {price.Monthly === price.Annually ? (
          price.Monthly
        ) : (
          <>
            <span
              aria-hidden={activePeriod === 'Annually'}
              className={clsx(
                'transition duration-300',
                activePeriod === 'Annually' &&
                  'pointer-events-none translate-x-6 select-none opacity-0',
              )}
            >
              {price.Monthly}
            </span>
            <span
              aria-hidden={activePeriod === 'Monthly'}
              className={clsx(
                'absolute left-0 top-0 transition duration-300',
                activePeriod === 'Monthly' &&
                  'pointer-events-none -translate-x-6 select-none opacity-0',
              )}
            >
              {price.Annually}
            </span>
          </>
        )}
      </p>
      <p
        className={clsx(
          'text-sm',
          featured ? 'text-white' : 'text-gray-700',
        )}
      >
        {description}
      </p>
      <div className="order-last mt-4">
        <ul
          role="list"
          className={clsx(
            '-my-2 divide-y text-sm',
            featured
              ? 'divide-gray-100 text-white'
              : 'divide-gray-200 text-gray-700',
          )}
        >
          {features.map((feature) => (
            <li key={feature} className="flex items-center py-2">
              <CheckIcon
                className={clsx(
                  'h-6 w-6 flex-none',
                  featured ? 'text-accent' : 'text-accent',
                )}
              />
              <span className="ml-4">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
      <Button bgColor={featured ? 'bg-white' : "bg-primary"} textColor={featured ? 'text-primary' : 'text-white'} label={button.label} onClick={featured ? () => loginWithRedirect() : () => loginWithRedirect()} />
    </section>
  )
}

export default function Pricing() {
  let [activePeriod, setActivePeriod] = useState('Monthly');  

  return (
    <section
      id="pricing"
      aria-labelledby="pricing-title"
      className="border-t border-gray-200 bg-gray-100 py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl text-center">
          <h2
            id="pricing-title"
            className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl"
          >
            Pricing
          </h2>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Use for free or upgrade to premium and support a solo developer trying to bring you the best notes you've ever seen.
          </p>
        </div>

        <div className="mt-8 flex justify-center">
          <div className="relative">
            <RadioGroup
              value={activePeriod}
              onChange={setActivePeriod}
              className="grid grid-cols-2"
            >
              {planTypes.map((planType) => (
                <RadioGroup.Option
                  key={planType.type}
                  value={planType.type}
                  className={clsx(
                    'cursor-pointer text-center border border-gray-300 px-[calc(theme(spacing.4)-1px)] py-[calc(theme(spacing.2)-1px)] text-sm text-gray-700 outline-2 outline-offset-2 transition-colors hover:border-gray-400',
                    planType.type === 'Monthly'
                      ? 'rounded-l-lg'
                      : '-ml-px rounded-r-lg',
                  )}
                >
                  {planType.text}
                </RadioGroup.Option>
              ))}
            </RadioGroup>
            <div
              aria-hidden="true"
              className={clsx(
                'pointer-events-none absolute inset-0 z-10 grid grid-cols-2 overflow-hidden rounded-lg bg-accent transition-all duration-300',
                activePeriod === 'Monthly'
                  ? '[clip-path:inset(0_50%_0_0)]'
                  : '[clip-path:inset(0_0_0_calc(50%-1px))]',
              )}
            >
              {planTypes.map((planType) => (
                <div
                  key={planType.type}
                  className={clsx(
                    'py-2 text-center text-sm font-semibold text-white',
                    planType.type === 'Annually' && '-ml-px',
                  )}
                >
                  {planType.text}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 items-start gap-x-24 gap-y-10 sm:mt-16 lg:max-w-5xl lg:grid-cols-2">
          {plans.map((plan) => (
            <Plan key={plan.name} {...plan} activePeriod={activePeriod} />
          ))}
        </div>
      </Container>
    </section>
  )
}
