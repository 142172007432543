import React, { Fragment, useEffect, useState, Suspense, lazy } from 'react';
import Loading from '../components/Atoms/Loading';
import { useAuth0 } from '@auth0/auth0-react';

const PageHeader = lazy(() => import('../components/Infrastructure/PageHeader'));

const pageHeaderActions = [
  { name: 'View Roadmap', icon: 'MapPin', onClick: () => window.open(`https://inkless.canny.io/`), group: false }
];

const BoardToken = '0da77bfb-88f2-471f-7faa-a956910926f9';

const Feedback = () =>
{
  const { user, isAuthenticated } = useAuth0();


  useEffect(() =>
  {
    (function (w, d, i, s) { function l() { if (!d.getElementById(i)) { var f = d.getElementsByTagName(s)[0], e = d.createElement(s); e.type = "text/javascript", e.async = !0, e.src = "https://canny.io/sdk.js", f.parentNode.insertBefore(e, f) } } if ("function" != typeof w.Canny) { var c = function () { c.q.push(arguments) }; c.q = [], w.Canny = c, "complete" === d.readyState ? l() : w.attachEvent ? w.attachEvent("onload", l) : w.addEventListener("load", l, !1) } })(window, document, "canny-jssdk", "script");

    if (isAuthenticated)
    {
      Canny('identify', {
        appID: '64e13f8c31d3de09577714ab',
        user: {
          // Replace these values with the current user's data
          email: user.email,
          name: user.name,
          id: user.sub,
          avatarURL: user.picture,

          // These fields are optional, but recommended:
          // created: new Date(user.created).toISOString(),
        },
      });
    }

    Canny('render', {
      boardToken: BoardToken,
      basePath: `/feedback`, // See step 2
      ssoToken: null, // See step 3,
      theme: localStorage.theme ? localStorage.theme : 'light', // options: light [default], dark, auto
    });
  }, []);

  return (
    <>
      <Suspense fallback={<Loading />}><PageHeader pageTitle={"Feedback"} actions={pageHeaderActions} subheader={null} /></Suspense>
      <div className="container mx-auto max-w-8xl overflow-auto py-4">
        <div data-canny />
      </div>
    </>
  );
}

export default Feedback;