import React, { useState, Fragment } from "react";
import { useCampaignContext } from "../../contexts/CampaignContext";
import { useQuery } from "@tanstack/react-query";
import { Dialog, Transition} from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { XCircleIcon } from "@heroicons/react/24/outline";
import SelectList from "../Atoms/SelectList";
import Modal from '../Molecules/Modal';
import Button from "../Atoms/Button";
import useUserInformation from "../../hooks/useUserInformation";
import { PERMISSIONS } from '../../utilities/Constants';

export default function PartyManager({CampaignId}) {    
  const [removePartyMemberDialogOpen, setRemovePartyMemberDialogOpen] = useState(false);
  const [removePartyMemberUserId, setRemovePartyMemberUserId] = useState(null);

  const campaignContext = useCampaignContext();
  const { getPartyMembers, useUpdatePartyMember, useDeletePartyMember } = campaignContext;    

  const { mutate: updatePartyMember } = useUpdatePartyMember();
  const { mutate: deletePartyMember } = useDeletePartyMember();

  const userInfo = useUserInformation(CampaignId);

  const { data } = useQuery({ queryKey: ["PartyMembers", CampaignId], queryFn: () => getPartyMembers(CampaignId), });  

  const CollabOptions = [    
    { value: 1, label: "Editor" },
    { value: 2, label: "Viewer" },
  ];

  function handleCollabOptionChange(pm, event) {
    let PMObject = {
      PartyMemberId: pm.UserId,
      CampaignId: CampaignId,
      Permissions: parseInt(event.target.value),
    };

    updatePartyMember(PMObject);
  }

  function handleRemovePartyMember(event) {
    let PMObject = {
      PartyMemberId: removePartyMemberUserId,
      CampaignId: CampaignId,
    };

    deletePartyMember(PMObject);
  }

  return (
    <>
      <div className="flex flex-col bg-background min-w-max h-full py-12 lg:px-4 items-center rounded-md overflow-hidden">
        {data?.length > 0 && (
          <div class="flex w-full max-w-4xl">
            <ul role="list" className="w-full divide-y divide-gray">
              {data.map((partyMember) => (
                <li
                  key={partyMember.Email}
                  className="flex justify-between gap-x-6 py-5 rounded p-4"
                >
                  <div className="flex min-w-0 gap-x-4">
                    {/* <img
                    className="h-12 w-12 flex-none rounded-full bg-gray-50"
                    src={partyMember?.imageUrl}
                    alt=""
                  /> */}
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 text-textprimary">
                        {partyMember.Nickname}
                      </p>
                      <p className="mt-1 flex text-xs leading-5 text-textprimary">
                        {/* <a
                        href={`mailto:${partyMember.email}`}
                        className="truncate hover:underline"
                      > */}
                        {partyMember.Email}
                      </p>
                      <p className="text-sm sm:hidden font-bold leading-6 text-textprimary">
                        {partyMember.Role}
                      </p>
                    </div>
                  </div>
                  <div className="flex shrink-0 items-center gap-x-6">
                    <div className="hidden sm:flex sm:flex-col sm:items-end">
                      <p className="text-sm font-bold leading-6 text-textprimary">
                        {partyMember.Role}
                      </p>
                    </div>
                    {!partyMember.IsOwner  && userInfo?.Permissions == PERMISSIONS.Editor && (
                      <>
                      <div>
                        <SelectList
                          onChange={(e) => handleCollabOptionChange(partyMember, e)}
                          Name={"Permissions"}
                          Options={CollabOptions}
                          Value={partyMember.Permissions}
                        />
                      </div>
                      <XCircleIcon className="w-6 h-6 text-error cursor-pointer" onClick={() => {setRemovePartyMemberUserId(partyMember.UserId); setRemovePartyMemberDialogOpen(true);}}  />
                       {/* <Menu as="div" className="relative flex-none">
                       <Menu.Button className="-m-2.5 block p-2.5 text-textprimary hover:text-gray-900">
                         <span className="sr-only">Open options</span>
                         <EllipsisVerticalIcon
                           className="h-5 w-5"
                           aria-hidden="true"
                         />
                       </Menu.Button>
                       <Transition
                         as={Fragment}
                         enter="transition ease-out duration-100"
                         enterFrom="transform opacity-0 scale-95"
                         enterTo="transform opacity-100 scale-100"
                         leave="transition ease-in duration-75"
                         leaveFrom="transform opacity-100 scale-100"
                         leaveTo="transform opacity-0 scale-95"
                       >
                         <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-background py-2 shadow-lg ring-1 ring-border focus:outline-none">
                           <Menu.Item>
                             {({ active }) => (
                               <a
                                 href="#"
                                 className={classNames(
                                   active ? "bg-gray-50" : "",
                                   "block px-3 py-1 text-sm leading-6 text-textprimary"
                                 )}
                               >
                                 View profile
                                 <span className="sr-only">
                                   , {partyMember.Nickname}
                                 </span>
                               </a>
                             )}
                           </Menu.Item>
                           <Menu.Item>
                             {({ active }) => (
                               <a
                                 href="#"
                                 className={classNames(
                                   active ? "bg-gray-50" : "",
                                   "block px-3 py-1 text-sm leading-6 text-textprimary"
                                 )}
                               >
                                 Message
                                 <span className="sr-only">
                                   , {partyMember.Nickname}
                                 </span>
                               </a>
                             )}
                           </Menu.Item>
                         </Menu.Items>
                       </Transition>
                     </Menu> */}
                     </>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <Modal
        isOpen={removePartyMemberDialogOpen}
        onClose={() => setRemovePartyMemberDialogOpen(false)}
      >
        <Dialog.Title
          as="h3"
          className="text-lg font-medium leading-6 text-gray-900 mb-4"
        >
          Remove From Party
        </Dialog.Title>

        <p>Are you sure you want to remove this party member?</p>

        <div className="mt-4 flex gap-2">
          <Button label={"Remove"} onClick={handleRemovePartyMember} />
          <Button
            label={"Cancel"}
            bgColor='bg-background'
            textColor="text-textprimary" 
            border="border-border"
            onClick={() => setRemovePartyMemberDialogOpen(false)}
          />
        </div>
      </Modal>

      
    </>
  );
}
