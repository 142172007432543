import React, { useState, useEffect } from 'react';
import './styles/App.scss';
import { Routes, Route } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { ErrorBoundary } from 'react-error-boundary';
import { ProtectedRoute } from './auth/ProtectedRoute';
import { useCampaignContext } from "./contexts/CampaignContext";
import ThemeSelector from './containers/ThemeSelector';
import "./fonts/NotoSans-Regular.ttf";


import CampaignList from "./routes/CampaignList";
import Campaign from "./routes/Campaign";
import Session from "./routes/Session";
import Landing from './routes/Landing';
import Join from './routes/Join';
import Oops from './routes/Oops';
import CampaignForm from './routes/CampaignForm';
import Account from './routes/Account';
import Preferences from './routes/Preferences';
import Feedback from './routes/Feedback';
import Discord from './routes/Discord';

import TopMenu from './components/Infrastructure/TopMenu';
import Loading from './components/Atoms/Loading';
import { PERMISSIONS } from './utilities/Constants';

export default function App(props) {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const campaignContext = useCampaignContext();
  const { currentTheme } = campaignContext;    

  useEffect(() => {
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, []) 
  

  function ErrorFallback({ error }) {
    return <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ThemeSelector currentTheme={currentTheme}>
      <div id="AppContainer">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <TopMenu />
          <div id="AppBody" className='flex flex-col flex-1 bg-background text-textprimary overflow-hidden'>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/feedback" element={<Feedback/>} />
              <Route path="/discord" element={<Discord />} />
              <Route path="campaigns">
                <Route index element={<ProtectedRoute component={CampaignList} />} />
                <Route path=":campaignId/edit" element={<ProtectedRoute component={CampaignForm}  PermissionsRequired={PERMISSIONS.Editor} />} />
                <Route path=":campaignId/session/:id" element={<ProtectedRoute component={Session} />} />
                <Route path=":campaignId/session/:id/:noteId" element={<ProtectedRoute component={Session} />} />                
                <Route path="/campaigns/:id" element={<ProtectedRoute component={Campaign} />} />
                <Route path="/campaigns/:id/join/:code" element={<ProtectedRoute component={Join} />} />
                <Route path="/campaigns/create" element={<ProtectedRoute component={CampaignForm} />} />
              </Route>
              <Route path="/settings/account" element={<ProtectedRoute component={Account} />} />
              <Route path="/settings/preferences" element={<ProtectedRoute component={Preferences} />} />
              <Route path="/settings/billing" element={<ProtectedRoute component={Preferences} />} />
              <Route path="*" element={<Oops />} />
            </Routes>
          </div>                 
        </ErrorBoundary>
      </div>
    </ThemeSelector>
  );
}