import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

const Auth0ProviderWithHistory = ({ children }) => {      
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={"dev-xichdi6z.us.auth0.com"}
      clientId={"TmuJhHcUsy45isZM2gmQDUvcaK3apAZ0"}
      redirectUri={window.location.origin + "/campaigns"}
      onRedirectCallback={onRedirectCallback}
      audience={"https://inkless.app/api"}
      //audience={'http://localhost:8090/api'}
      scope="read:current_user update:current_user_metadata"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;