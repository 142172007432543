import React, { lazy, Suspense } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { useCampaignContext } from "../../contexts/CampaignContext";

import Loading from "../Atoms/Loading";
const Note = lazy(() => import("../Molecules/Note"));

export default function NoteList({
  CampaignId,
  SessionNotesData,
  paramNoteId,
  NoteTypesQuery,
  KeysQuery,
  KeyWordsQuery,
}) {
  const campaignData = useCampaignContext();
  const { updateSortOrder } = campaignData;

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    let SourceNote = SessionNotesData[source.index];
    let DestinationNote = SessionNotesData[destination.index];

    let Source = { index: source.index, Note: SourceNote };
    let Destination = { index: destination.index, Note: DestinationNote };

    // Filter out the note we want to move
    SessionNotesData = SessionNotesData.filter((x) => x.Id !== SourceNote.Id);

    // Re-insert it in the new position (destination)
    SessionNotesData.splice(destination.index, 0, SourceNote);

    // Update our back end sort order
    updateSortOrder(Destination, Source);
  };

  if (
    NoteTypesQuery.status !== "success" &&
    KeysQuery.status !== "success" &&
    KeyWordsQuery.status !== "sucess"
  )
    return <Loading />;

  return (
    <div id="NoteList" className="py-4 flex flex-col gap-y-2 overflow-hidden h-full">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="NoteList">
          {(dropProvided) => (
            <div ref={dropProvided.innerRef} {...dropProvided.droppableProps} className="flex flex-col overflow-auto h-full">
              {SessionNotesData &&
                SessionNotesData.map((note, index) => {
                  let containerClassList = note.Id === Number(paramNoteId) ? "animate-pulse-3" : "";

                  return (
                    <Suspense key={index} fallback={<Loading />}>
                      <Note
                        key={index}
                        note={note}
                        index={index}
                        keys={KeysQuery.data || []}
                        noteTypes={NoteTypesQuery.data || []}
                        keysQuery={KeysQuery}
                        keyWordsQuery={KeyWordsQuery}
                        containerClassList={containerClassList}
                        campaignId={CampaignId}
                      />
                    </Suspense>
                  );
                })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
