// Libraries
import React, { Fragment, useState, useEffect, useRef, Suspense, lazy } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Node, Transforms } from 'slate';
import { useCampaignContext } from "../contexts/CampaignContext";
import { Dialog, Transition } from '@headlessui/react';
import moment from "moment";
import useUserInformation from "../hooks/useUserInformation";
import { PERMISSIONS } from '../utilities/Constants';
import GifSearchControl from "../components/Organisms/GifSearchControl";

//Components
import Button from "../components/Atoms/Button";
import Loading from "../components/Atoms/Loading";
import NoteList from "../components/Organisms/NoteList";
import Modal from "../components/Molecules/Modal";
import EmptyStateSession from "../components/Molecules/EmptyStateSession";

const PageHeader = lazy(() => import('../components/Infrastructure/PageHeader'));
const NoteTypeList = lazy(() => import("../components/Atoms/NoteTypeList"));
const SlateEditor = lazy(() => import("../components/Organisms/SlateEditor"));

export default function Session()
{
  const campaignData = useCampaignContext();
  const { deleteSession, useAddNote, useAddGifNote, currentCampaignId, setCurrentCampaignId, setCurrentSessionId, currentCampaign, getKeys, getSession, getSessionNotes, getNoteTypes, getKeyWords, extractTextFromNodes, getPartyMembers } = campaignData;

  const {mutate : AddNote} = useAddNote();
  const {mutate : AddGifNote} = useAddGifNote();

  let navigate = useNavigate();
  const { campaignId, id, noteId: paramNoteId } = useParams();
  const CampaignId = parseInt(campaignId);
  const SessionId = parseInt(id);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const userInfo = useUserInformation(CampaignId);

  const [currentSession, setCurrentSession] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [noteType, setNoteType] = useState(null);
  const [clearEditorValue, setClearEditorValue] = useState(false);
  const initialValue = [{ type: 'paragraph', children: [{ text: '' }] }];

  const { isLoading: sessionIsLoading, data: sessionData, error: sessionError } = useQuery({ queryKey: ["Session"], queryFn: () => getSession(CampaignId, SessionId)});
  const SessionNotesQuery = useQuery({ queryKey: ["Notes"], queryFn: () => getSessionNotes(CampaignId, SessionId)});
  const KeysQuery = useQuery({ queryKey: ["Keys"], queryFn: () => getKeys(campaignId)});
  const NoteTypesQuery = useQuery({ queryKey: ["NoteTypes"], queryFn: () => getNoteTypes(campaignId)});
  const KeyWordsQuery = useQuery({ queryKey: ["Keywords"], queryFn: () => getKeyWords(campaignId)});
  

  // Store locally so we can manually manipulate for things like quickly setting sort order.
  let SessionNotesData = SessionNotesQuery.data;

  const handleAddNote = (noteValue, editor) =>
  {
    let FilteredNoteContent = noteValue[0].children.filter(x => x.text != '');

    let SlateJSON = JSON.stringify(FilteredNoteContent);

    let SearchableText = extractTextFromNodes(FilteredNoteContent);

    FilteredNoteContent = FilteredNoteContent.map((item) =>
    {
      if (item.hasOwnProperty('type') == false)
      {
        return ({ type: 'note', value: item.text });
      }

      return item;
    });

    const JSONValue = JSON.stringify(FilteredNoteContent);

    let NewNote = { noteJSON: JSONValue, NoteTypeId: noteType.Id }

    AddNote(
      {
        event: null,
        note: NewNote,
        slateValue: SlateJSON,
        searchableText: SearchableText,
        sessionId: SessionId
      });

    Transforms.select(editor, [0]);
    const point = { path: [0, 0], offset: 0 };
    editor.selection = { anchor: point, focus: point }; // this would cause an error on resetting after creating a note with a memo
    editor.children = initialValue; // resets editor to blank state
    setClearEditorValue(true);

    return;
  }

  const handleAddGif = (gif) =>
  {
    AddGifNote(
    {
      campaignId: currentCampaignId,
      noteText: gif.media_formats.gif.url,
      sessionId: SessionId,
      searchText: gif.content_description,
    });

    return;
  }

  const handleDeleteSession = () =>
  {
    deleteSession(currentSession);
    setIsDeleteDialogOpen(false);
    navigate("/campaigns/" + currentCampaignId);
  }
 
  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.addEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  // Checks if the current campaign Id is set. if not, check the current url and try to get that campaign (Ex: user refresh)
  useEffect(() =>
  {
    if (currentCampaignId === null)
    {
      if (CampaignId != undefined)
        setCurrentCampaignId(CampaignId);
    }

    setCurrentSessionId(SessionId);
  }, []);

  useEffect(() =>
  {
    if (!sessionIsLoading && !sessionError && sessionData.length > 0)
    {
      setCurrentSession(sessionData[0]);
    }
  }, [sessionIsLoading, sessionError, sessionData])

  const pageHeaderActions = [
    { name: 'Delete Session', icon: 'Trash', onClick: () => { setIsDeleteDialogOpen(true) }, group: false, visibleTo: PERMISSIONS.Editor },
  ];

  const subheaderInfo = [
    { name: '', data: moment(currentSession?.CreatedDate).format("MMMM Do, YYYY"), icon: 'Calendar' },
    // {name: 'Session End:', data: moment(currentSession.CreatedDate).format("MMMM Do, YYYY"), icon: 'Calendar'}
  ]

  const isMobile = windowWidth <= 768; // Determine if a device is mobile based on the window width;

  return (
    <>
      <Suspense fallback={<Loading />}><PageHeader actions={pageHeaderActions} subheader={subheaderInfo} pageTitle={currentSession?.Title} titleEditable={true} /></Suspense>
      <div className="overflow-hidden themeBackgroundImage">
        <div className="container mx-auto h-full flex sm:flex-col flex-col-reverse max-w-7xl pt-4 px-4 sm:px-6 overflow-hidden">
        
        {userInfo?.Permissions == PERMISSIONS.Editor &&
         <div className={"flex gap-1 flex-col items-start sm:flex-row sm:items-end relative"}>
          <div className="flex flex-shrink">
            {NoteTypesQuery.data?.length > 0 && <Suspense fallback={<Loading />}><NoteTypeList NoteTypes={NoteTypesQuery.data} onChange={setNoteType} /></Suspense>}
          </div>

          <div className="flex w-full relative">
          <Suspense fallback={<Loading />}>
            <SlateEditor
              value={initialValue}
              onSubmit={handleAddNote}
              clearValue={clearEditorValue}
              keysQuery={KeysQuery}
              keyWordsQuery={KeyWordsQuery}
              placeHolder={isMobile && `Type Enter/Return to submit a note.`} />
          </Suspense>

          <GifSearchControl onClick={handleAddGif} />
          </div>
        </div>}
        
        {(!SessionNotesData || SessionNotesData?.length == 0) && <EmptyStateSession />}

        {SessionNotesData?.length > 0 && <NoteList CampaignId={CampaignId} KeyWordsQuery={KeyWordsQuery} KeysQuery={KeysQuery} NoteTypesQuery={NoteTypesQuery} SessionNotesData={SessionNotesData} paramNoteId={paramNoteId} />}
        </div>
      </div>

      <Modal
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-textprimary mb-4">
          Delete Session
        </Dialog.Title>

        <p>
          Are you sure you want to delete this session?
        </p>

        <div className="mt-4 flex gap-2">
          <Button label={"Delete"} onClick={() => handleDeleteSession(false)} />
          <Button label={"Cancel"} bgColor='bg-background' textColor="text-textprimary" border="border-border" onClick={() => setIsDeleteDialogOpen(false)} />
        </div>
      </Modal>
    </>
  );
}