import React from 'react';
import { useState, useEffect } from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useCampaignContext } from "../contexts/CampaignContext";
import { useParams,useNavigate } from 'react-router-dom';
import Loading from "../components/Atoms/Loading";
import Button from '../components/Atoms/Button';

export default function Join()
{    
    const campaignContext = useCampaignContext();        
    const { id, code } = useParams();
    let navigate = useNavigate();
    
    const [joinedCampaign, setJoinedCampaign] = useState(null);

    const campaignData = useCampaignContext();
    const { joinCampaign } = campaignData;    

    const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();

    useEffect(() =>
    {
        async function handleJoin()
        {
            if(!isAuthenticated)
                return;

            console.log("CampaignId: " + id + " | InviteCode: " + code);

            const token = await getAccessTokenSilently();

            let response = await joinCampaign(id, code);

            if(response.status >= 200 && response.status < 300 && response.data.length)
            {
                console.log(response.data[0]);
                setJoinedCampaign(response.data[0][0]);                         
            }
            
        }

        handleJoin();
    }, []);

    if(isLoading || joinedCampaign == null)
        return <Loading />

    if(isAuthenticated === false)
    {
        loginWithRedirect({
            appState: {
                returnTo: '/join/' + id
            }
        })

        return <div>Redirecting...</div>
    }
    else{
        return (
            <main className="grid min-h-full place-items-center bg-background px-6 py-24 sm:py-32 lg:px-8">
                <div className="text-center">
                    <p className="text-base font-semibold text-green-400">Success! Welcome to </p>
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-primary sm:text-5xl">{joinedCampaign?.Name}</h1>
                    <p className="mt-6 text-base leading-7 font-medium text-gray-600">Get Ready for Adventure</p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <Button label={"Home"} onClick={() => navigate("/")} />
                        <a onClick={() => navigate("/campaigns/" + joinedCampaign?.Id)} className="text-sm font-semibold cursor-pointer text-gray-900">
                            View Campaign <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>
                </div>
            </main>
        );
    }

    
}
