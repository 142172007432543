// Libs & Context
import React, { useState, useEffect } from "react";
import moment from "moment";
// Hooks
import { useNavigate } from "react-router-dom";
// Components & Icons
import {
  ChevronRightIcon,
  ClockIcon,
  CalendarIcon,
  ChartBarSquareIcon,
} from "@heroicons/react/24/outline";

export default function CampaignCard({ campaign, theme }) {

  const navigate = useNavigate();

  const handleCampaignClick = (campaign) => {    
    navigate(`/campaigns/${campaign.Id}`);
  };

  return (
    <div className={`Themed ${theme.Name}`}>
      <li
        key={campaign.Id}
        onClick={() => handleCampaignClick(campaign)}
        className={`themeBackgroundImage CampaignCard group block sm:p-4 shadow-lg shadow-shadow rounded-md cursor-pointer hover:bg-secondaryhover hover:shadow-shadowhover`}
      >
        <div className="flex items-center py-3 px-4 sm:py-4 sm:px-4">
          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
            <div className="truncate">
              <div className="flex text-2xl">
                <p className="CampaignTitle truncate font-medium text-primary group-hover:text-white">
                  {campaign.Name}
                </p>
              </div>
              {/* Meta */}
              <div className="mt-2 flex-1 space-y-2 md:space-y-0 md:flex gap-4">
                <div className="flex items-center text-sm text-textprimary group-hover:text-white">
                  <CalendarIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-textprimary group-hover:text-white"
                    aria-hidden="true"
                  />
                  <p>
                    Created on{" "}
                    {moment(campaign.CreatedDate).format("MM/DD/YYYY")}
                  </p>
                </div>
                <div className="flex items-center text-sm text-textprimary group-hover:text-white">
                  <ClockIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-textprimary group-hover:text-white"
                    aria-hidden="true"
                  />
                  <p className="flex gap-2">
                    Last played on{" "}
                    {campaign?.UpdateDate
                      ? moment(campaign.UpdateDate).format("MM/DD/YYYY")
                      : moment(campaign.CreatedDate).format(
                          "MM/DD/YYYY"
                        )}
                  </p>
                </div>
                <div className="flex items-center text-sm text-textprimary group-hover:text-white">
                  <ChartBarSquareIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-textprimary group-hover:text-white"
                    aria-hidden="true"
                  />
                  <p>{campaign.SessionCount} Sessions</p>
                </div>
              </div>
            </div>
          </div>
          <div className="ml-5 flex-shrink-0">
            <ChevronRightIcon
              className="h-5 w-5 text-textprimary"
              aria-hidden="true"
            />
          </div>
        </div>
      </li>
    </div>
  );
}
