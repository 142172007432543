import React from "react";

const LogoIcon = ({ fill, className }) => ( 

  <svg
    version="1.1"
    id="Icon_White"        
    viewBox="0 0 2500 2500"  
    className={className}
  >
    <g>
      <path
        className={fill || "fill-primary"}
        d="M2114.88,1123.67c-68.69-62.72-170.92,33.67-292.64,29.78c-155.57-4.96-178.69-111.36-178.69-238.9
		c0-357.21-289.57-646.78-646.78-646.78C639.57,267.77,350,557.34,350,914.55c0,141.06,45.16,271.58,121.81,377.88
		c30.08,41.72,199.32,237.83,25.81,354.36c-192.8,129.5-55.68,291.34,62.15,229.19c84.51-44.58,138.99-286.12,233.07-313.35
		c49.2-14.24,93.69,34.39,102.29,125.6c6.65,70.52-50.82,176.33-81.58,283.57c-101,352.2,378.1,340.55,305.58,5.18
		c-19.34-89.44-67.8-185.44-101-296.52c-33.67-112.65,79.7-109.73,139.84-47.91c46.61,47.91,55.68,143.73,124.3,182.57
		c58.01,32.83,130.78-5.18,116.54-78.99c-9.61-49.8-49.96-103.92-82.87-163.15c-38.84-69.92-45.29-129.4-1.29-146.32
		c101-38.85,247.42,334.34,366.44,485.57c172.21,218.83,343.55,22.12,240.84-123.01c-59.56-84.17-312.95-213.04-372.92-359.97
		c-51.79-126.9,24.46-227.39,129.49-230.48c88.05-2.59,168.41,89.73,255.08,110.06C2143.37,1358.04,2193.38,1195.35,2114.88,1123.67
		z M1078,968.39c-56.6,82.07-136.88-11.36-160.35-47.53c-49.43-76.14-138.61-225.41-12.6-280.01
		C1058.46,574.38,1131.44,890.91,1078,968.39z M1471.77,850.34c-61.49,33.84-93.46-64.22-139.65-138.6
		c-83.04-133.7,62.63-211.96,134.9-89.58C1509.62,694.29,1523.98,821.61,1471.77,850.34z"
      />
    </g>
  </svg>
);

export default LogoIcon;
