import React from "react";

import { BookOpen } from "lucide-react";

function EmptyStateSession() {  

  return (
    <div className="flex flex-col items-center h-full justify-center">
      <BookOpen className="w-10 h-10" color="var(--primary)" />
      <h3 className="mt-2 text-sm font-semibold text-textprimary">No notes yet!</h3>
      <p className="mt-1 text-sm text-textprimary">Can't wait to find out what happens next!</p>      
    </div>
  );
}

export default EmptyStateSession;
