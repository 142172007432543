import React, { Fragment, useEffect, useState } from 'react';
import { useCampaignContext } from "../contexts/CampaignContext";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useQuery } from "@tanstack/react-query";
import { Dialog, Transition } from '@headlessui/react';
import PageHeader from '../components/Infrastructure/PageHeader';
import Input from '../components/Atoms/Input';
import Button from '../components/Atoms/Button';
import SelectList from "../components/Atoms/SelectList";
import Modal from '../components/Molecules/Modal';
import PartyManager from '../components/Organisms/PartyManager';
import { Scroll, Users } from 'lucide-react';

const starterPlayerArr = ['1', '2', '3', '4']

export default function CampaignForm() {
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const campaignContext = useCampaignContext();
    const { getCampaign, addCampaign, useUpdateCampaign, setCurrentCampaignId, useDeleteCampaign, getThemes, setFormTheme, formTheme, setCurrentTheme } = campaignContext;
    const { campaignId } = useParams();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState("General");
    
    const {mutate: updateCampaign} = useUpdateCampaign();
    const {mutate : deleteCampaign} = useDeleteCampaign();

    const newCampaign = { Name: "", StartDate: new Date().toLocaleDateString('en-CA'), ThemeId: formTheme?.Id || 0 };

    const {isLoading : campaignIsLoading, data : campaignData, error: campaignError} = useQuery({ queryKey: ["Campaign"], queryFn: () => getCampaign(campaignId)});
    const ThemesQuery = useQuery({ queryKey: ["Themes"], queryFn: () => getThemes()});

    const secondaryNavigation = [
        { name: 'General', icon: Scroll, current: activeTab == "General", onClick: () => setActiveTab("General")},
        { name: 'Party Members', icon: Users, current: activeTab == "PartyMembers", onClick: () => setActiveTab("PartyMembers") },
      ];   

    useEffect(() => {
        
        if(window.location.pathname == "/campaigns/create")
            return;

        if(!campaignIsLoading && !campaignError && campaignData.length)
        {
            let tempCampaignData = {...campaignData[0]};
            tempCampaignData.ThemeId = formTheme?.Id != null ? formTheme.Id : tempCampaignData.ThemeId;
            tempCampaignData.StartDate = new Date(tempCampaignData.StartDate).toISOString().split('T')[0];
            setCampaignState(tempCampaignData);
        }
      }, [campaignIsLoading, campaignError, campaignData])

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [CampaignState, setCampaignState] = useState(newCampaign);
    const [PlayersState, setPlayersState] = useState(starterPlayerArr);
    let themeOptions = [];

    if(ThemesQuery){
        themeOptions = ThemesQuery.data?.map(theme => ({
            value: theme.Id,
            label: theme.DisplayName,
        }));        
    }

    useEffect(() => InitializePage(), []);

    const InitializePage = () => {
        if (campaignId && location.pathname.includes("edit"))
            setIsEditMode(true);

        if (CampaignState.StartDate === null) {
            setCampaignState({
                ...CampaignState,
                StartDate: new Date().toISOString().split('T')[0]
            })
        }
    }

    const getPlayers = async (campaignId) => {
        const token = await getAccessTokenSilently();

        let config = {
            headers: { Authorization: `Bearer ${token}` },
            params: { CampaignIds: campaignId }
        }

        await axios.get(`https://inkless.app/api/players/`, config).then((response) => {
            let filterPlayers = response.data.filter((x) => {
                return x.CampaignId == campaignId;
            });

            setPlayersState(filterPlayers);
        });
    }

    const handleInputChange = (event) => {
        setCampaignState({
            ...CampaignState,
            [event.target.name]: event.target.value
        })
    }

    const handleEditPlayers = function (event, index) {
        let tempPlayers = [...PlayersState];
        tempPlayers[index].CharacterName = event.target.value;
        setPlayersState(tempPlayers);
    }

    const handleAddPlayers = () => {
        setPlayersState([...PlayersState, { PlayerName: "", CharacterName: "" }]);
    }

    const handleRemovePlayer = (event, index) => {
        let newFormValues = [...PlayersState];
        newFormValues.splice(index, 1);
        setPlayersState(newFormValues)
    }

    const handleAddCampaign = async () => {
        // invoke addCamapign to get new campaign.id and redirect to
        try {
            const newCampaignId = await addCampaign(CampaignState, PlayersState)            
            setCurrentCampaignId(newCampaignId);
            navigate(`/campaigns/${newCampaignId}`);
        } catch (err) {
            console.error(err)
        }
    }

    const handleUpdateCampaign = () => {
        updateCampaign(CampaignState, PlayersState);        
        setCurrentTheme({Id: CampaignState.ThemeId, Name: CampaignState.ThemeId != 0 ? ThemesQuery.data?.find((x) => x.Id == CampaignState.ThemeId)?.Name : "Default"});
        navigate(`/campaigns/${CampaignState.Id}`);
    }

    const handleDeleteCampaign = () => {
        deleteCampaign(campaignId);
        setIsDeleteDialogOpen(false);
        navigate(`/campaigns`)
    }

    const handleThemeChange = (e) => {        
        setFormTheme({Id: parseInt(e.target.value), Name: e.target.value != 0 ? ThemesQuery.data?.find((x) => x.Id == e.target.value)?.Name : "Default"});
        setCampaignState({
            ...CampaignState,
            ThemeId: parseInt(e.target.value)
        })
    }

    function classNames(...classes)
    {
        return classes.filter(Boolean).join(' ')
    }

    const ContentMap = {
        General:
            <form className="space-y-8 px-4 py-4">
                <div className="space-y-8">
                    <div className="flex flex-col gap-2">

                        <div className="grid grid-cols-2 gap-x-4 gap-3">
                            <div className="">
                                <Input label={"Campaign Name"} value={CampaignState?.Name} type="text" name={"Name"} onChange={handleInputChange} />
                            </div>
                            <div className="">
                                <Input label={"Start Date"} value={CampaignState?.StartDate} type="date" name={"StartDate"} onChange={handleInputChange} />
                            </div>
                            <div>
                                <SelectList
                                    Label={"Campaign Theme"}
                                    onChange={(e) => handleThemeChange(e)}
                                    Value={CampaignState?.ThemeId}
                                    Name={"Theme"}
                                    Options={themeOptions}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Form Footer */}
                <div className="flex justify-between  pt-5">
                    <div>
                        {isEditMode && <Button label={'Delete Campaign'} onClick={() => setIsDeleteDialogOpen(true)} bgColor={'bg-error'} hoverColor={'hover:bg-errorhover'} textColor={'text-white'} />}
                    </div>
                    <div className='flex self-end'>
                        <Button label={isEditMode ? 'Save Campaign' : 'Create Campaign'} onClick={isEditMode ? handleUpdateCampaign : handleAddCampaign} />
                    </div>
                </div>

            </form>,
        PartyMembers: <PartyManager CampaignId={parseInt(campaignId)} />
    }

    return (
        <>
            <PageHeader pageTitle={isEditMode ? "Campaign Settings" : "New Campaign"} actions='' />
            <div className="lg:p-4 overflow-hidden bg-gray shadow shadow-shadow themeBackgroundImage">
                <div className='grid grid-cols-1 lg:grid-cols-8 gap-4 lg:mx-auto'>
                    <aside className="ThemeBackground rounded p-2 col-span-5 lg:col-span-2 overflow-x-auto border-b border-gray-900/5 lg:py-4 lg:block lg:flex-none lg:border-0">
                        <nav className="flex-none px-4 sm:px-6 lg:px-0">
                            <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
                                {secondaryNavigation.map((item) => (
                                    <li key={item.name}>
                                        <a
                                            onClick={item.onClick}
                                            className={classNames(
                                                item.current
                                                    ? 'text-texttertiary bg-primary'
                                                    : 'text-textprimary hover:text-accent',
                                                'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold cursor-pointer'
                                            )}
                                        >
                                            <item.icon
                                                className={classNames(
                                                    item.current ? 'text-texttertiary' : 'text-textprimary group-hover:text-accent',
                                                    'h-6 w-6 shrink-0'
                                                )}
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </aside>

                    <div className='hidden lg:col-span-1 lg:flex'></div>

                    <div className="col-span-5 lg:col-span-4 mx-2 border-border border rounded ThemeBackground">
                        {ContentMap[activeTab]}
                    </div>
                </div>
            </div>

              {/* Delete Campaign Dialog */}
            <Modal
                isOpen={isDeleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
            >
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-textprimary mb-4">
                    Delete Campaign
                </Dialog.Title>

                <p>
                    Are you sure you want to delete this campaign?
                </p>

                <div className="mt-4 flex gap-2">
                    <Button label={"Delete"} onClick={() => handleDeleteCampaign(false)} bgColor={'bg-error'} hoverColor={'hover:bg-errorhover'} />
                    <Button label={"Cancel"} bgColor='bg-background' textColor="text-textprimary" border="border-border" onClick={() => setIsDeleteDialogOpen(false)} />
                </div>
            </Modal>
        </>
    )
}