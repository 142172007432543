import React from "react";

export default function SelectList({
  Label,
  Options,
  Value,
  Name,
  Id,
  onChange,
  Error,
}) {
  return (
    <div>
      <label htmlFor="location" className="block text-sm font-medium leading-6 text-textprimary">
        {Label}
      </label>
      <select
        id={Id}
        name={Name}
        className="bg-background block w-full rounded-md border-0 py-2 pl-3 pr-10 text-textprimary ring-1 ring-inset ring-border focus:ring-2 focus:ring-textprimary sm:text-sm sm:leading-6"
        value={Value}        
        onChange={(e) => onChange(e)}
      >
        {Options?.map((option, index) => {
          return <option key={index} value={option.value}>{option.label}</option>
        })}
      </select>
    </div>
  );
}
