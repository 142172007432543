import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { GifIcon } from "@heroicons/react/24/outline";
import { useAuth0 } from "@auth0/auth0-react";

const GifSearchControl = ({onClick}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [categoryResults, setCategoryResults] = useState([]);
  const divRef = useRef(null);

  const { getAccessTokenSilently } = useAuth0();  

  const toggleSearchBar = () => {
    setIsOpen(!isOpen);
    setSearchResults([]); // Clear search results when hiding the search bar
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = async (event) => {
    event?.preventDefault();
    
    const token = await getAccessTokenSilently();
    // use axios to call my gif search API with the search term
    const response = await axios.get('https://inkless.app/api/gifsearch', {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        searchTerm: searchTerm,
        limit: 10
      }
    });

    setSearchResults(response.data.results);
  };

  const getCategories = async () => {
    const token = await getAccessTokenSilently();
    // use axios to call my gif search API with the search term
    const response = await axios.get('https://inkless.app/api/gifcategories', {
      headers: { Authorization: `Bearer ${token}` }      
    });

    setCategoryResults(response.data.tags);
  };

  const handleCategoryClicked = async (category) => {
    setSearchTerm(category);
    
    const token = await getAccessTokenSilently();
    // use axios to call my gif search API with the search term
    const response = await axios.get('https://inkless.app/api/gifsearch', {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        searchTerm: category,
        limit: 10
      }
    });

    setSearchResults(response.data.results);
  }
  
  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }
  
  useEffect(() => {
    const TimoutId = setTimeout(() => {
      if(searchTerm === '') return setSearchResults([]);

      handleSearchSubmit();
    }, 400);
    return () => clearTimeout(TimoutId);
  }, [searchTerm]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() =>{
    getCategories();
  }, []);
  

  return (
    <>
      <GifIcon className='stroke-textprimary fill-background absolute h-6 w-6 top-[6px] right-5 translate-x-1/2 cursor-pointer' onClick={toggleSearchBar} />

      {isOpen && (
        <div ref={divRef} className='absolute flex flex-col gap-2 right-4 top-10 w-[420px] h-[404px] overflow-auto p-2 rounded ThemeBackground'>          
          <input
            type="text"
            className='text-textprimary bg-background border border-textprimary rounded-md p-1 w-full'
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search Tenor"
          />
          <div className='flex flex-wrap'>
            {searchResults.length ? searchResults.map((gif) => (
                <img onClick={() => {onClick(gif); setIsOpen(false);}  } className='cursor-pointer w-1/2 h-min' key={gif.id} src={gif.media_formats.gif.url} alt="gif" />
            )) :
            categoryResults.map((gif, index) => (
              <div className='cursor-pointer relative overflow-hidden w-48 h-24 rounded' onClick={() => handleCategoryClicked(gif.searchterm)}>
                <img className='absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2' key={index} src={gif.image} alt="gif"/>
                <div class="absolute inset-0 bg-black opacity-50"></div>
                <div class="absolute inset-0 flex items-center justify-center">
                  <span class="text-white text-xl font-bold">{gif.searchterm}</span>
                </div>                
              </div>
            )) 
          }
          </div>
        </div>
      )}
    </>
  );
};

export default GifSearchControl;
